.wrapper {
  flex: 1;
  background: #f5f5f5;
}

.header {
  background: var(--grey-2);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 24px;
  border-bottom: 1px solid var(--grey-4);
  box-shadow: 0px 4px 40px rgba(129, 129, 129, 0.05);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.text-header {
  padding: 16px 24px;
}

.logo {
  height: 36px;
  width: 99px;
  display: block;
}

.card {
  padding: 60px;
  box-shadow: 0px 4px 40px rgba(129, 129, 129, 0.05);
  background: #fff;
  border-radius: 12px;
}

.header + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wrapper {
  text-align: center;
}

.greeting {
  font-weight: 800;
  margin-bottom: 8px;
}

.signup-prompt {
  display: block;
  margin-bottom: 40px;
}

.forgot-password-wrapper {
  text-align: right;
  margin-bottom: 24px;
}

.divider:global(.ant-divider-horizontal) {
  margin: 24px 0;
}

.error {
  margin-bottom: 24px;
}

.oauth-login {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
